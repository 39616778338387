import React from "react";
import {db} from "../services/firebaseConfig";
import {Link} from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import ShowMoreText from 'react-show-more-text';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import NavHeader from "./NavHeader";
import DriverSeriesStatsTable from "./DriverSeriesStatsTable";
import LeagueStandingsTable from "./LeagueStandingsTable";
import '../App.css';

class RaceSeriesPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            raceSeriesId: this.props.raceSeriesId,
            raceSessions: [],
            raceSeries: null//{ name: "" }
            //   content: '',
            //   readError: null,
            //   writeError: null
        };
    }

    async componentDidMount() {
        const { match: { params } } = this.props;
        this.setState({ readError: null });
        try {

            db.collection("raceSeries")
                .doc(params.raceSeriesId) //params.raceSeriesId)
                .onSnapshot(
                    snapshot => {
                        let raceSeries = snapshot.data()
                        raceSeries["id"] = snapshot.id
                        this.setState({ raceSeries });
                    });

            db.collection("raceSeries")
                .doc(params.raceSeriesId) //params.raceSeriesId)
                .collection("events")
                .orderBy("startDateTime", "asc")
                .onSnapshot(
                    snapshot => {
                        let raceSessions = [];
                        snapshot.forEach((snap) => {
                            let object = snap.data()
                            object["id"] = snap.id
                            raceSessions.push(object);//snap.data());
                        });
                        this.setState({ raceSessions });
                    });
        } catch (error) {
            this.setState({ readError: error.message });
        }
    }

    render() {

        const { match: { params } } = this.props;
        let raceSeriesId = this.state.raceSeries?.id ?? ""
        let runsAsLeague = this.state.raceSeries?.runsAsLeague ?? false

        return (
            <>
                <NavHeader />

                <div style={{ padding: "10px 20px", textAlign: "left"}}>
                    <h2>{this.state.raceSeries?.name}</h2>

                    <div style={{ background: "rgba(235, 235, 235, 0.8)", borderRadius: "12px", padding: "10px 10px" }}>
                        <div dangerouslySetInnerHTML={{ __html: this.state.raceSeries?.summaryHtml }} />
                        <ShowMoreText
                            /* Default options */
                            lines={3}
                            more='Show more'
                            less='Show less'
                            expanded={false}
                        >
                        <div dangerouslySetInnerHTML={{ __html: this.state.raceSeries?.descriptionHtml }} />
                        </ShowMoreText>
                    </div>

                    <div><p></p></div>

                    <Tabs defaultActiveKey="events" id="uncontrolled-tab-example">
                        <Tab eventKey="events" title="Events">


                    <ListGroup style={{ padding: "10px 0px"}}>

                        {this.state.raceSessions.map(function(item) {
                            let hasSubSessionId = !(typeof item.subSessionId === 'undefined' || item.subSessionId === null)
                            return <ListGroup.Item>
                                <Container fluid style={{ padding: "0px 0px" }}>
                                    <Row className="align-items-center">
                                        <Col>
                                            <p style={{ fontWeight: "bold"}}>{item.trackName}</p>
                                        </Col>
                                        <Col>
                                            <p>{item.startDateTime.toDate().toLocaleString('en-gb', { hour: '2-digit', minute: '2-digit', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} </p>
                                        </Col>
                                        <Col md="auto">
                                            <Link to={`/raceSession/${raceSeriesId}/${item.id}`}>
                                                <Button style={{ minWidth:150 }} variant="flat" disabled={hasSubSessionId ? false : true} >{hasSubSessionId ? "View Results" : "Upcoming"}</Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </ListGroup.Item>
                        })}
                    </ListGroup>

                        </Tab>

                        {runsAsLeague ?
                            <Tab eventKey="standings" title="Standings">
                                <LeagueStandingsTable raceSeriesId={params.raceSeriesId}/>
                            </Tab>
                            :
                            <Tab eventKey="drivers" title="Drivers">
                                <DriverSeriesStatsTable raceSeriesId={params.raceSeriesId}/>
                            </Tab>
                        }

                    </Tabs>
                </div>
            </>
        );
    }
}

export default RaceSeriesPage;
