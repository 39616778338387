import { useState, useEffect } from 'react';
import React, { Component } from 'react';
import { Link, BrowserRouter as Router, Route } from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { db } from '../services/firebaseConfig';
import NavHeader from './NavHeader';
import App from "../App";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Select from "react-select";

class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            allRaceSeries: [],
            raceSeries: [],
            latestResults: [],
            content: '',
            readError: null,
            writeError: null,
            catOptions: [{ value: "Running", label: "Running" }, { value: "Archived", label: "Archived" }],
            selectedOption: { value: "Running", label: "Running" }
        };
    }

    static filterStats(series, selectedOption) {
        let result = []
        series.forEach( (object) => {
            if ((selectedOption.value === "Running" && object.isCompleted !== true) ||
            (   selectedOption.value === "Archived" && object.isCompleted === true)) {
                result.push(object);
            }
        })
        return result
    };

    static getDerivedStateFromProps(props, state) {

        let series = HomePage.filterStats(state.allRaceSeries, state.selectedOption);
        let latestResults = []
        state.allRaceSeries.forEach( (object) => {
            if (object.latestResult != null) {

                const date1 = object.latestResult.startDateTime.toDate();
                const date2 = new Date();
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                if (diffDays <= 14) {
                    latestResults.push(object)
                }
            }
        })
        return { catOptions: state.catOptions, selectedOption: state.selectedOption,
            raceSeries: series, latestResults: latestResults }
    }

    async componentDidMount() {
        this.setState({ readError: null });
        try {
            db.collection("raceSeries")
                //.where("isPublic", "==", true)
                .orderBy("name", "asc")
                .onSnapshot(
                    snapshot => {
                        let allRaceSeries = [];
                        snapshot.forEach((snap) => {
                            let object = snap.data()
                            object["id"] = snap.id
                            if (object.isPublic === true) {
                                allRaceSeries.push(object);
                            }
                        });
                        let raceSeries = HomePage.filterStats(allRaceSeries, this.state.selectedOption);
                        this.setState({ allRaceSeries, raceSeries });
                    });
        } catch (error) {
            this.setState({ readError: error.message });
        }
    }

    handleChange(selectedOption) {
        this.setState({ selectedOption: selectedOption });
        console.log(`Option selected:`, selectedOption);
    };

    render() {

        return(
            <div className="App">

                <NavHeader />

                <div style={{ padding: "10px 20px", textAlign: "left"}}>
    
                <h4 className="d-none" >Recent Results</h4>
                <div className="d-none" style={{ display: "flex", overflowX: "auto" }}>

                {this.state.latestResults.map(function(item) {
                    return <ResultSummary raceSeries={item}/>
                })}

                </div>

                    <div style={{ padding: "20px 0px"}}>


                <Tabs defaultActiveKey="series" id="home-tab">
                    <Tab eventKey="series" title="Race Series">

                <div style={{ padding: "10px 0px", textAlign: "left"}}>

                <div>
                        <Select
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                            options={this.state.catOptions}
                        />
                        <p></p>
                    </div>

                <ListGroup>
                    {this.state.raceSeries.map(function(item) {
                        return <ListGroup.Item>
                            <Container fluid style={{ padding: "0px 0px" }}>
                                <Row className="align-items-center" >
                                    <Col style={{ padding: "0px 0px" }}>
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    <h3>{item.name}</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div dangerouslySetInnerHTML={{ __html: item.summaryHtml }} />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    <Col md="auto">
                                        <Link to={`/raceSeries/${item.id}`}>
                                            <Button variant="flat">View</Button>
                                        </Link>

                                    </Col>
                                </Row>
                            </Container>
                        </ListGroup.Item>
                    })}
                </ListGroup>
                </div>
                    </Tab>

                    <Tab eventKey="calendar" title="This Week">
                        <div style={{ maxWidth:"100%", maxHeight: "100vh", height: "auto" }}>
                            <img style={{ maxWidth:"100%", maxHeight: "100vh", height: "auto" }} src="Race_Calendar-28Mar.png"/>
                        </div>
                    </Tab>
                </Tabs>
                </div>
            </div>
            </div>
        )
    }
}

const TodoComponent = {
    //width: "300px",
    //textAlign: "left",
    margin: "30px auto",
    backgroundColor: "#F0F0F0",
    //minHeight: "200px",
    boxSizing: "border-box"
}

class ResultSummary extends React.Component {

    carClass(carClassItem, numberOfClasses) {
        let medals = ["🥇 ", "🥈 ", "🥉 "]
        var i = 0
        if (numberOfClasses > 1) {
            return (
                <div>{medals[0]}{carClassItem.winners[0]}{" - " + carClassItem.name}</div>
            )
        } else {
            return (
                <div>
                {carClassItem.winners.map(function(item) {
                    return <div>{medals[i++]}{item}</div>
                })}
            </div>
            )
        }

    }

    render() {

        let latestResult = this.props.raceSeries.latestResult
        let carClassRender = this.carClass

        return (
            <div style={{ textAlign: "center",
                background: "rgba(123, 63, 26, 0.1)",
                border: '2px solid',
                borderRadius: "6px",
                borderColor: "rgba(123, 63, 26, 0.6)",
                marginRight: "20px",
                minWidth: "300px",
                padding: "10px 10px" }}>

                <h4>{this.props.raceSeries.name}</h4>
                <h9>{latestResult.trackName} - {latestResult.startDateTime.toDate().toLocaleString('en-gb', {  year: 'numeric', month: 'numeric', day: 'numeric' })} </h9>
                <p/>
                { latestResult.carClass.map(function(item) { return carClassRender(item, latestResult.carClass.length) }) }

            </div>
        )
    }
}

export default HomePage;
