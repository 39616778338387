import React from "react";
import {db} from "../services/firebaseConfig";
import Table from "react-bootstrap/Table";
import { withWindowSizeListener } from 'react-window-size-listener';

class SimSessionResultsTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            raceSessionId: this.props.raceSessionId,
            raceSeriesId: this.props.raceSeriesId,
            simsesnum: this.props.simsesnum,
            carClass: this.props.carClass,
            isRace: this.props.isRace,
            results: [],
            allResults: []
        }
    }

    static getDerivedStateFromProps(props, state) {

        let results = [];
        state.allResults.forEach((result) => {
            if (props.carClass === "All" || props.carClass === result.ccNameShort) {
                results.push(result);//snap.data());
            }
        });
        return { isRace:props.isRace, isMultiClass: props.isMultiClass,  carClass: props.carClass, results: results}
    }

    async componentDidMount() {

        this.setState({ readError: null });
        try {

            db.collection("raceSeries")
                .doc(this.state.raceSeriesId) //params.raceSeriesId)
                .collection("events")
                .doc(this.state.raceSessionId)
                .collection("results")
                .where("simsesnum", "==", this.state.simsesnum)
                //.where(db.FieldPath(["config", "showOnWebsite"]), "==", true)
                .orderBy("finishpos", "asc")
                .onSnapshot(
                    snapshot => {
                        let results = [];
                        let allResults = [];
                        let hasPoints = false
                        snapshot.forEach((snap) => {
                            let object = snap.data();
                            if (this.state.carClass === "All" || this.state.carClass === object.ccNameShort) {
                                results.push(object);//snap.data());
                            }
                            allResults.push(object)
                            if (object.leaguePoints > null) {
                                hasPoints = true
                            }
                        });
                        this.setState({ allResults: allResults });
                        this.setState({ results: results });
                        this.setState({ hasPoints: hasPoints });
                    });
        } catch (error) {
            this.setState({ readError: error.message });
        }
    }

    formatInterval(interval, prefix) {

        var nf = new Intl.NumberFormat('en-GB', { minimumIntegerDigits: 2, minimumFractionDigits: 3, maximumFractionDigits: 3 })

        let mins = Math.floor(interval / 10000 / 60)
        let seconds = interval - (mins * 60 * 10000)

        if (interval === 0) {
            return ""
        }
        if (interval === -1) {
            return ""
        }
        var value = prefix
        if (mins > 0) {
            value = value + mins + ":"
        } else {
            nf = new Intl.NumberFormat('en-GB', { minimumIntegerDigits: 1, minimumFractionDigits: 3, maximumFractionDigits: 3 })
        }
        value = value + nf.format(seconds / 10000.0)
        return value
    }

    render() {

        let carClass = this.state.carClass
        let isMultiClass = this.state.isMultiClass
        let isRace = this.state.isRace
        let hasPoints = this.state.hasPoints
        let isWide = this.props.windowSize.windowWidth > 500

        let formatInterval = this.formatInterval

        return (
            <>
                <div style={{ padding: "10px 0px", textAlign: "left"}}>

                    <Table striped bordered hover size="sm" responsive="sm">
                        <thead>
                        <tr>
                            <th>Pos</th>
                            <th>Name</th>
                            { isMultiClass ? <th>Class</th> : ""}
                            <th>Int</th>
                            { isRace ? <th>Lead</th> : ""}
                            { (isRace && isWide) ? <th>Best</th> : ""}
                            <th>Inc</th>
                            { hasPoints ? <th>Pts</th> : ""}
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.results.map(function(item) {
                            return <tr>
                                <td>{carClass === "All"  ? (item.finishpos + 1) : (item.finishposinclass + 1)}</td>
                                <td>{item.displayname.replace(/\d+$/, "")}</td>
                                { isMultiClass ? <td>{item.ccNameShort}</td> : ""}
                                <td style={{ fontVariant: "tabular-nums"}}>
                                    {formatInterval((carClass === "All"  ? item.interval : item.classinterval),"-")}
                                </td>
                                { isRace ? <td>{item.lapslead}</td> : "" }
                                { (isRace && isWide) ? <td style={{ fontVariant: "tabular-nums"}}>
                                    {formatInterval(item.bestlaptime, "")} </td> : "" }
                                <td>{item.incidents}</td>
                                { hasPoints ? <td>{item.leaguePoints}</td> : "" }
                            </tr>
                        })}
                        </tbody>
                    </Table>

                </div>
            </>
        )
    }
}

export default withWindowSizeListener(SimSessionResultsTable);