import React from "react";
import {db} from "../services/firebaseConfig";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Select from 'react-select';
import SimSessionResultsTable from "./SimSessionResultsTable";
import NavHeader from "./NavHeader";
import ReactPlayer from "react-player"

class RaceSessionPage extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            raceSeriesId: null,
            raceSeriesName: null,
            raceSessionId: null,
            event: null,
            simSessions: [],
            selectedOption: { value: "All", label: "All classes" },
            options: []
        };
    }

    async componentDidMount() {
        const {match: {params}} = this.props;

        this.setState({ raceSeriesId: params.raceSeriesId,
            raceSessionId: params.raceSessionId});
        try {

            db.collection("raceSeries")
                .doc(params.raceSeriesId) //params.raceSeriesId)
                .onSnapshot(
                    snapshot => {
                        let series = snapshot.data()
                        this.setState({ raceSeriesName: series.name });
                    });

            db.collection("raceSeries")
                .doc(params.raceSeriesId) //params.raceSeriesId)
                .collection("events")
                .doc(params.raceSessionId)
                .onSnapshot(
                    snapshot => {
                        let event = snapshot.data()
                        event["id"] = snapshot.id
                        let options = [{ value: "All", label: "All classes" }]
                        event.carClasses.forEach( carClass => {
                            options.push({ value: carClass.ccNameShort, label: carClass.ccNameShort })
                        })
                        this.setState({ options: options });
                        this.setState({ event: event });
                    });

            db.collection("raceSeries")
                .doc(params.raceSeriesId) //params.raceSeriesId)
                .collection("events")
                .doc(params.raceSessionId)
                .collection("simSessions")
                //.where(db.FieldPath(["config", "showOnWebsite"]), "==", true)
                .orderBy("simsesnum", "asc")
                .onSnapshot(
                    snapshot => {
                        let simSessions = [];
                        snapshot.forEach((snap) => {
                            let object = snap.data();

                            if (object["config"]["showOnWebsite"] === true) {
                                object["id"] = snap.id
                                simSessions.push(object);//snap.data());
                            }
                        });
                        this.setState({ simSessions: simSessions });

                    });
        } catch (error) {
            this.setState({ readError: error.message });
        }
    }

    handleChange(selectedOption) {
        this.setState({ selectedOption: selectedOption });
        console.log(`Option selected:`, selectedOption);
    };

    render() {

        const raceSeriesId = this.state.raceSeriesId
        const raceSessionId = this.state.raceSessionId
        const handleChange = this.handleChange
        const options = this.state.options

        const isMultiClass = this.state.options.length > 2
        const selectedOption = this.state.selectedOption;

        const primaryVideoUrl = this.state.event?.primaryVideoUrl ?? ""
        const hasYouttubeVideo = primaryVideoUrl.length > 0;

        const raceReportHtml = this.state.event?.raceReportHtml.length > 0 ? this.state.event?.raceReportHtml : "<p>Awaiting race report...</p>"

        return (
            <>
                <NavHeader />

                <div style={{ padding: "10px 20px", textAlign: "left"}}>

                    <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Row xs={1} md={hasYouttubeVideo ? 2 : 1}>
                            <Col>
                                <h3>{this.state.raceSeriesName}</h3>
                                <h4>{this.state.event?.trackName}</h4>
                                <h5>{this.state.event?.startDateTime.toDate().toLocaleString('en-gb', { hour: '2-digit', minute: '2-digit', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} </h5>

                                <div style={{ background: "rgba(235, 235, 235, 0.8)", borderRadius: "12px", padding: "10px 10px" }}
                                     dangerouslySetInnerHTML={{ __html: raceReportHtml }}
                                />

                            </Col>
                            {hasYouttubeVideo ?
                            <Col md={6}>
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player'
                                        url={primaryVideoUrl}
                                        width='100%'
                                        height='100%'
                                        controls={true}
                                    />
                                </div>
                            </Col> : <div></div> }
                        </Row>
                    </Container>

                    <div><p></p></div>

                    { isMultiClass ?
                    <Select
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                        options={options}
                    /> : <div></div> }

                    <div><p></p></div>

                    <Tabs defaultActiveKey="0" id="sessions-tab-example">
                    {this.state.simSessions.map(function(item) {
                        return <Tab eventKey={item.simsesnum} title={item.simsesname}>

                            <SimSessionResultsTable carClass={selectedOption.value}
                                                    isMultiClass={isMultiClass}
                                                    isRace={item.config.countInRaceAggregates}
                                                    raceSeriesId={raceSeriesId}
                                                    raceSessionId={raceSessionId}
                                                    simsesnum={item.simsesnum}/>

                        </Tab>
                    })}
                </Tabs>

                </div>
            </>
        )
    }
}

export default RaceSessionPage;