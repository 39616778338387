
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase';

// Add the Firebase products that you want to use
//import 'firebase/firestore';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {

    apiKey: "AIzaSyDcYf6HkHOwu3Cx0ZamcpoqSWs2nC2E9co",
    authDomain: "test-4a39e.firebaseapp.com",
    databaseURL: 'https://test-4a39e.firebaseio.com',
    projectId: "test-4a39e",
    storageBucket: "test-4a39e.appspot.com",
    messagingSenderId: "920499780716",
    appId: "1:920499780716:web:0a9719ca5e8a6ea6cd6c49"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth;
export const db = firebase.firestore();