import React, { Component } from 'react';
import Navbar from "react-bootstrap/Navbar";

const Header = {
    fontSize: "20px",
    fontWeight: 'bold'
}

class NavHeader extends React.Component {

    render() {
        return (
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="/" style={Header}>
                    <img
                        alt=""
                        src="/robot_orange.svg"
                        width="60"
                        height="60"
                        className="d-inline-block align-middle"
                    />{'   '}
                    Sampsoid Community Series
                </Navbar.Brand>
            </Navbar>
        )
    }
}

export default NavHeader;