import React from "react";
import {db} from "../services/firebaseConfig";
import Table from "react-bootstrap/Table";

class DriverSeriesStatsTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            raceSeriesId: this.props.raceSeriesId,
            stats: []
        }
    }

    async componentDidMount() {

        this.setState({ readError: null });
        try {

            db.collection("raceSeries")
                .doc(this.state.raceSeriesId) //params.raceSeriesId)
                .collection("driverStats")
                .orderBy("displayName", "asc")
                .onSnapshot(
                    snapshot => {
                        let stats = [];
                        snapshot.forEach((snap) => {
                            let object = snap.data()
                            object["id"] = snap.id
                            stats.push(object);
                        });
                        this.setState({ stats });
                    });
        } catch (error) {
            this.setState({ readError: error.message });
        }
    }

    render() {

        let nf = new Intl.NumberFormat('en-GB', { minimumIntegerDigits: 1, minimumFractionDigits: 1, maximumFractionDigits: 1 })

        return (
            <>
                <div style={{ padding: "10px 0px", textAlign: "left"}}>

                    <Table striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Events</th>
                            <th>Class Wins</th>
                            <th>Inc/Race</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.stats.map(function(item) {
                            return <tr>
                                <td>{item.displayName.replace(/\d+$/, "")}</td>
                                <td>{Math.round(item.eventParticipation * 100.0)}% </td>
                                <td>{item.classRaceWins}</td>
                                <td>{nf.format(item.incidentsPerRace)}</td>
                            </tr>
                        })}
                        </tbody>
                    </Table>

                </div>
            </>
        )
    }
}

export default DriverSeriesStatsTable;