import './App.css';

import React, { Component } from 'react';
import { Link, BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from './components/HomePage';
import RaceSeriesPage from './components/RaceSeriesPage';
import RaceSessionPage from './components/RaceSessionPage';

class App extends React.Component {

  render() {
    return (
        <>
          <Router>
            <Route exact path="/" component={HomePage}/>
            <Route path="/raceSession/:raceSeriesId/:raceSessionId" component={RaceSessionPage} />
            <Route path="/raceSeries/:raceSeriesId" component={RaceSeriesPage} />
          </Router>
        </>
    )
  }
}

export default App;
