import React from "react";
import {db} from "../services/firebaseConfig";
import Table from "react-bootstrap/Table";
import Select from "react-select";

class LeagueStandingsTable extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            raceSeriesId: this.props.raceSeriesId,
            stats: [],
            allResults: [],
            catOptions: [{ value: "All", label: "All categories" }],
            selectedOption: { value: "All", label: "All categories" },
            usesLicense: false
        }
    }

    static filterStats(stats, selectedOption) {
        let result = []
        stats.forEach( (object) => {
            if (selectedOption.value === "All" || selectedOption.value === object.leagueCategory) {
                result.push(object);
            }
        })
        return result
    };

    static getDerivedStateFromProps(props, state) {

        let stats = LeagueStandingsTable.filterStats(state.allResults, state.selectedOption);
        return { raceSeriesId: props.raceSeriesId, catOptions: state.catOptions, selectedOption: state.selectedOption,
            stats: stats }
    }

    async componentDidMount() {

        this.setState({ readError: null });
        try {

            db.collection("raceSeries")
                .doc(this.state.raceSeriesId) //params.raceSeriesId)
                .collection("driverStats")
                .orderBy("leaguePointsTotal", "desc")
                .onSnapshot(
                    snapshot => {
                        let allResults = [];
                        let catOptionsMap = { "All": { value: "All", label: "All categories" } };
                        let usesLicense = false
                        snapshot.forEach((snap) => {
                            let object = snap.data()
                            object["id"] = snap.id
                            allResults.push(object);
                            if (object["leagueCategory"] != null) {
                                catOptionsMap[object["leagueCategory"]] = { value: object["leagueCategory"], label: object["leagueCategory"] }
                            }
                            if (object["licensePointsTotal"] != 0) {
                                usesLicense = true
                            }
                        });
                        let stats = LeagueStandingsTable.filterStats(allResults, this.state.selectedOption);
                        let catOptions = []
                        Object.keys(catOptionsMap).forEach(e => catOptions.push(catOptionsMap[e]));
                        this.setState({ allResults, stats, catOptions, usesLicense });
                    });
        } catch (error) {
            this.setState({ readError: error.message });
        }
    }

    handleChange(selectedOption) {
        this.setState({ selectedOption: selectedOption });
        console.log(`Option selected:`, selectedOption);
    };

    render() {

        const catOptions = this.state.catOptions
        let isMultiCat = catOptions.length > 2
        let usesLicense = this.state.usesLicense

        return (
            <>
                <div style={{ padding: "10px 0px", textAlign: "left"}}>

                    { isMultiCat ?
                        <div>
                            <Select
                                value={this.state.selectedOption}
                                onChange={this.handleChange}
                                options={catOptions}
                            />
                            <p></p>
                        </div>: ""
                    }

                    <Table striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th>Pos</th>
                            <th>Name</th>
                            { isMultiCat ? <th>Cat</th> : ""}
                            <th>Points</th>
                            { usesLicense ? <th>License</th> : ""}
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.stats.map(function(item, index) {
                            return <tr>
                                <td>{index + 1}</td>
                                <td>{item.displayName.replace(/\d+$/, "")}</td>
                                { isMultiCat ? <td>{item.leagueCategory}</td> : "" }
                                <td>{item.leaguePointsTotal}</td>
                                { usesLicense ? <td>{item.licensePointsTotal}</td> : ""}
                                
                            </tr>
                        })}
                        </tbody>
                    </Table>

                </div>
            </>
        )
    }
}

export default LeagueStandingsTable